<template>
  <div>
    <v-card tile outlined class="titleCard px-5 py-2 rounded-lg" :class="colorIndex % 2 === 0 ? 'item1' : 'item2'">
      <!-- #region Дата оформления -->
      <info-chip class="on-border-right pr-0" title="Дата оформления" :value="createDate">
      </info-chip>

      <v-chip class="on-border-left text-h5 px-5 py-5" :color="statusColor">
        {{ statusText }}
      </v-chip>

      <!-- #endregion -->
      <!-- #region Инфа о полете -->
      <v-row class="pa-2 mt-1">
        <v-col md="10" cols="12">
          <v-row align="center" justify="space-between" class="px-3">
            <!-- #region картинка и авиакомпания -->
            <v-col md="2" cols="12" align-self="center" justify-center>
              <v-img :src="item.flights[0].carrier.imgUrl" :max-height="$vuetify.breakpoint.mobile ? '90' : '100'"
                contain></v-img>
              <div class="text-h5 text-center indigo--text">
                {{ item.flights[0].carrier.name }}
              </div>
            </v-col>
            <!-- #endregion -->
            <!-- #region информация о полете -->
            <v-col md="10" cols="12">
              <flight-info :data="item.flights"></flight-info>
            </v-col>
            <!-- #endregion -->
          </v-row>
          <!-- #endregion -->
          <!-- #region Divider Пассажир -->
          <v-row justify="center" class="divider mt-1">
            <v-divider class="dashed-divider"></v-divider>
            <span>Пассажиры</span>
            <v-divider class="dashed-divider"></v-divider>
          </v-row>
          <!-- #endregion -->
          <!-- #region Информация пассажиров -->
          <v-row>
            <v-col md="4" cols="12" v-for="(pass, i) in item.passengers" :key="i">
              <ticket-passengers-info :item="pass" :ticket="item.tickets"
                :contactInfo="item.contactInfo"></ticket-passengers-info>
            </v-col>
          </v-row>
          <!-- #endregion -->
          <!-- #region Divider Служебная информация-->
          <v-row justify="center" class="divider mt-1">
            <v-divider class="dashed-divider"></v-divider>
            <span>Служебная информация</span>
            <v-divider class="dashed-divider"></v-divider>
          </v-row>
          <v-row justify="center" justify-md="start" align-content="center" class="mt-1">
            <v-col sm="auto" md="11" class="service-info">
              <info-chip title="Цена"
                :value="$store.getters.user.role === $enums.Roles.SubAgent ? item.cost.total - item.cost.shadowTax : item.cost.total" />
              <info-chip v-if="$store.getters.user.role === $enums.Roles.Admin" title="Базовая цена"
                :value="item.cost.base.toString()" class="ml-2" />
              <info-chip v-if="$store.getters.user.role === $enums.Roles.Admin && item.cost.shadowTax > 0"
                title="Скрытая часть" :value="item.cost.shadowTax.toString()" class="ml-2 mr-5" />
              <info-chip v-if="$store.getters.user.role !== $enums.Roles.SubAgent" title="Поставщик"
                :value="item.provider" class="ml-2" />
              <info-chip v-if="$store.getters.user.role !== $enums.Roles.SubAgent" title="GDS" :value="item.gds"
                class="ml-2" />
              <info-chip v-if="$store.getters.user.role === $enums.Roles.Admin" title="Юрлицо"
                :value="item.providerConfigOwner" class="ml-2" />
            </v-col>
            <v-col md="1">
              <v-btn icon @click="getStatus" :loading="statusing">
                <v-icon size="30" color="orange">mdi-head-question</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- #endregion -->
          <!-- #region Divider Кассир -->
          <v-row justify="center" class="divider mt-1" v-if="$store.getters.user.role === $enums.Roles.Admin">
            <v-divider class="dashed-divider"></v-divider>
            <span>Кассир</span>
            <v-divider class="dashed-divider"></v-divider>
          </v-row>
          <!-- #endregion -->
          <!-- #region Информация о кассире -->
          <v-row align="center" no-gutters justify="center" justify-md="start" class="mt-3"
            v-if="$store.getters.user.role === $enums.Roles.Admin || $store.getters.user.role === $enums.Roles.Technologist">
            <v-col cols="2" sm="2" md="1">
              <v-img :src="getImg" :max-height="50" contain></v-img>
            </v-col>
            <v-col cols="auto" sm="auto" class="text-center">
              <span class="text-h5 font-weight-bold" @click="CopyToBuffer(item.createdBy.name)">
                {{ item.createdBy.name }}
              </span>
            </v-col>
            <v-col cols="12" sm="auto" class="text-center">
              <span class="text-h6 orange--text darken-4--text ml-5" @click="CopyToBuffer(item.createdBy.email)">
                {{ item.createdBy.email }}
              </span>
            </v-col>
          </v-row>
          <!-- #endregion -->
        </v-col>
        <!-- #region Кнопки -->

        <v-col md="2" cols="12">
          <v-row align-content="start" class="btn-group" justify="center">
            <v-btn v-if="haveFullTickets" width="90%" class="mt-2 move" color="green" elevation="0" :loading="isDownload"
              @click="downloadReceipt">
              Загрузить М/К
            </v-btn>
            <v-btn v-if="item.status === $enums.TicketStatusCodes.Booked" width="90%" class="mt-2 move" color="orange"
              elevation="0" :loading="buing" @click="onBuy">
              Оформить
            </v-btn>
            <v-btn v-if="item.status === $enums.TicketStatusCodes.Booked" width="90%" class="mt-2 move" color="red"
              elevation="0" @click="onCancelBook" :loading="canceling">
              Отмена брони
            </v-btn>
            <v-btn v-if="item.status === $enums.TicketStatusCodes.Bought" width="90%" class="mt-2 move" color="red"
              elevation="0" :loading="refunding" @click="onGetRefundData">
              Возврат
            </v-btn>
            <v-btn v-if="(item.status === $enums.TicketStatusCodes.Bought ||
              item.status === $enums.TicketStatusCodes.VoidBooked)
              " width="90%" class="mt-2 move" color="`#764E15" elevation="0" :loading="voiding" @click="onVoid">
              Аннулирование ({{ allowedToRefundMs > 0 ? allowedToRefundTime : 'запрос' }})
            </v-btn>
            <v-btn width="90%" class="mt-5 move" color="orange" @click="getStatus" :loading="statusing">
              Проверить статус
            </v-btn>
          </v-row>
          <v-col v-if="item.status !== $enums.TicketStatusCodes.Booked &&
            item.status !== $enums.TicketStatusCodes.Bought
            " class="not-ticket text-center" width="90%">
            <span class="orange--text text-h3">NOT</span>
            <v-img :src="require($vuetify.theme.dark
              ? '@/assets/img/ticket-dark.png'
              : '@/assets/img/ticket-light.png')
              " :max-height="150" :max-width="300" contain class="mt-2"></v-img>
          </v-col>
        </v-col>
        <!-- #endregion -->
      </v-row>

      <div v-if="refundDialog" class="text-center">
        <v-dialog persistent width="50%" v-model="refundDialog">
          <refund-modal :ticket="item.workId" :toRefund="refundData.toRefund" :penalties="refundData.penalty"
            :refundable="refundData.refundable" :errorReason="refundData.reasonPhrase"
            @cancel="(val) => (refundDialog = val)" @onRefund="refund">
          </refund-modal>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import ApiCalls from "@/mixins/booking/ApiCalls";
import DateFormat from "@/mixins/DateFormat";
import ErrorModal from "@/components/UI/ErrorModal";
import InfoChip from "@/components/UI/InfoChip.vue";
import TicketPassengersInfo from "@/components/SoldedTicketsPage/TicketPassengerInfo.vue";
import { format } from "date-fns";
import FlightInfo from "@/components/SoldedTicketsPage/FlightInfo.vue";
import ClipboardData from "@/mixins/ClipboardData.js";
import RefundModal from "@/components/SoldedTicketsPage/RefundModal.vue";
import { tr } from "date-fns/locale";
import { downloadFile } from '@/mixins/utils.js';

export default {
  name: "SoldedTicketItem",
  components: { InfoChip, ErrorModal, TicketPassengersInfo, FlightInfo, RefundModal },
  mixins: [DateFormat, ApiCalls, ClipboardData],
  props: {
    item: {
      type: Object,
      require: true,
    },
    colorIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      canceling: false,
      currentTime: new Date(),
      pdfUrl: "",
      voiding: false,
      refunding: false,
      buing: false,
      isDownload: false,
      statusing: false,
      refundDialog: false,
      refundData: null,
      updateTimeoutId: null,
      allowUpdate: true,
      internalStatus: this.item.status
    };
  },
  mounted: function () {
    if (this.isNeedUpdate)
      this.updateIntervalId = setInterval(() => this.updateByTime(), 10 * 1000)
    var _this = this;
    this.timer = setInterval(function () {
      _this.currentTime = new Date();
    }, 1000);
  },
  beforeDestroy: function () {
    if (!!this.updateIntervalId)
      clearInterval(this.updateIntervalId);
    this.updateIntervalId = null;
  },
  computed: {
    isNeedUpdate() {
      return ((this.item.status == 3) && (this.item.tickets.length == 0 || this.item.tickets.some(e => !e.number)))
        || this.item.status == 10
        || this.item.status == 11
    },
    haveFullTickets() {
      return !!this.item.tickets && !!this.item.tickets.length && this.item.tickets.every(e => !!e.number)
    },
    statusText() {
      return this.statusTextN(this.item.status)
    },
    // Поменять возврат статуса на int позжу

    createDate() {
      return format(new Date(this.item.createdAt), "HH:mm dd.MM.yyyy");
    },
    getImg() {
      return require(this.item.createdBy.role === this.$enums.Roles.User
        ? "@/assets/img/b2c-ico.png"
        : this.item.createdBy.role === this.$enums.Roles.Admin
          ? "@/assets/img/admin-ico.png"
          : "@/assets/img/cashier-ico.png");
    },
    statusColor() {
      let color;
      switch (this.item.status) {
        case 3:
        case 10:
          color = "green";
          break;
        case 2:
        case 11:
          color = "orange";
          break;
        default:
          color = "red";
          break;
      }
      return color;
    },
    allowedToRefundMs() {
      return (
        780000 +
        (Date.parse(this.item.createdAt) - Date.parse(this.currentTime))
      );
    },
    allowedToRefundTime() {
      return this.fmtMSS(this.allowedToRefundMs);
    },
    isSubagent() {
      return this.$store.getters.user.role == this.$enums.Roles.SubAgent;
    },
  },
  methods: {
    statusTextN(status) {
      switch (status) {
        case 2:
          return "Забронирован";
        case 3:
          return "Оформлен";
        case 4:
          return "Бронь отменена";
        case 5:
          return "Аннулирован";
        case 6:
          return "Возвращен";
        case 7:
          return "Не изменился";
        case 8:
          return "Аннулирован (требуется отмена брони)";
        case 9:
          return "Возвращен";
        case 10:
          return "Идет процесс выписки";
        case 11:
          return "Идет процесс возврата";
        default:
          return "Неизвестно";
      }
    },
    textToStatus(status) {
      console.log(status)
      switch (status) {
        case "BOOKED":
          return 2;
        case "BOUGHT":
          return 3;
        case "BOOK_CANCELED":
          return 4;
        case "VOID":
          return 5;
        case "RETURNED":
          return 6;
        case "NOT_CHANGED":
          return 7;
        case "VOID_BOOKED":
          return 8;
        case "REFUNDED":
          return 9;
        case "BOUGHT_IN_PROCESS":
          return 10;
        case "REFUND_IN_PROCESS":
          return 11;
        default:
          return 0;
      }
    },
    async downloadReceipt() {
      this.isDownload = true;
      try {
        let resp = await this.$axios.get(
          this.$enums.Endpoints.Data.SevicePdf + `?data=${this.item.workId}`,
          { responseType: "blob" }
        );
        downloadFile(resp.data, { name: `${this.item.workId}_receipt.pdf`, openInNewTab: false })
      } catch (e) {
        throw e;
      } finally {
        this.isDownload = false;
      }
    },
    async getStatus() {
      try {
        this.statusing = true;
        console.log(this.item.workId)
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Order.Status + `?data=${encodeURIComponent(this.item.workId)}`
        );
        this.$store.commit("setSnackbar", {
          show: true,
          message: this.statusTextN(this.textToStatus(resp.data)),
          color: resp.data == "BOUGHT" ? "green" : "orange",
          timeout: 2000,
          contentClass: "text-center text-h5",
        });
        this.item.status = this.textToStatus(resp.data);
      } finally {
        console.warn("Не удалось получить статус");
        this.statusing = false;
      }
    },
    async onBuy() {
      // this.$store.commit("setMessage", "что хотите оплатить билет");
      // this.$store.commit("openDialog", true);
      try {
        this.buing = true;
        console.warn("Оформление билета");
        let resp1 = await this.$axios.post(
          this.$enums.Endpoints.Order.Buy + `?data=${this.item.workId}`
        );
        this.item.status = 3;
        await this.$store.dispatch("updateBallance");
        console.warn("Билет оформлен");
      } finally {
        console.warn("Билет не оформлен");
        this.buing = false;
      }
    },
    async onCancelBook() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          await this.cancelBook();
        },
      });
      this.$store.commit("setMessage", "что хотите отменить бронь");
      this.$store.commit("openDialog", true);
    },
    openPdf() {
      this.pdfUrl = this.item.tickets[0]?.receiptUrl;
      let downloadLink = this.pdfUrl;
      return window.open(downloadLink);
    },
    async onVoid() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          await this.void();
        },
      });
      this.$store.commit("setMessage", "что хотите аннулировать билет");
      this.$store.commit("openDialog", true);
    },
    async void() {
      this.voiding = true;
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Order.Void + `?data=${this.item.workId}`
        );

        if (!!resp.data)
          this.item = resp.data
        if (this.item.status == 11)
          this.updateIntervalId = setInterval(() => this.updateByTime(), 10 * 1000)
        //this.item.status = !!resp.data.status ? resp.data.status : this.item.status;

        await this.$store.dispatch("updateBallance");
      } catch (e) {
        throw e;
      } finally {
        this.voiding = false;
      }
    },
    async cancelBook() {
      this.canceling = true;
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Order.Cancel + `?data=${this.item.workId}`
        );
        this.item.status = 4;
      } catch (e) {
        throw e;
      } finally {
        this.canceling = false;
      }
    },
    async refund() {
      this.refundDialog = false;
      this.item.status = 6;
      await this.$store.dispatch("updateBallance");
    },
    async onGetRefundData() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          await this.getRefundData();
        },
      });
      this.$store.commit("setMessage", "что хотите вернуть билет. Будут расчитаны условия возврата");
      this.$store.commit("openDialog", true);
    },
    async getRefundData() {
      this.refunding = true;
      try {
        let resp = await this.$axios.get(
          this.$enums.Endpoints.Order.RefundData + `?id=${this.item.workId}`
        );
        this.refundData = resp.data;
        // this.refundData = {
        //   toRefund: [{ "id": "2206709457488", "type": "Ticket", "isRefundable": true, "base": 0, "total": 4953, "taxes": [{ "amount": 885, "code": "DE" }, { "amount": 2083, "code": "RA" }, { "amount": 1337, "code": "RI" }, { "amount": 648, "code": "UH" }] }, { "id": "2206709457489", "type": "Ticket", "isRefundable": true, "base": 0, "total": 4953, "taxes": [{ "amount": 885, "code": "DE" }, { "amount": 2083, "code": "RA" }, { "amount": 1337, "code": "RI" }, { "amount": 648, "code": "UH" }] }, { "id": "2206709457490", "type": "Ticket", "isRefundable": true, "base": 0, "total": 4953, "taxes": [{ "amount": 885, "code": "DE" }, { "amount": 2083, "code": "RA" }, { "amount": 1337, "code": "RI" }, { "amount": 648, "code": "UH" }] }],
        //   penalty: [{ "id": "2206709457488", "type": "Ticket", "isRefundable": true, "base": 0, "total": 4953, "taxes": [] }, { "id": "2206709457489", "type": "Ticket", "isRefundable": true, "base": 0, "total": 4953, "taxes": [{ "amount": 885, "code": "DE" }, { "amount": 2083, "code": "RA" }, { "amount": 1337, "code": "RI" }, { "amount": 648, "code": "UH" }] }, { "id": "2206709457490", "type": "Ticket", "isRefundable": true, "base": 0, "total": 4953, "taxes": [{ "amount": 885, "code": "DE" }, { "amount": 2083, "code": "RA" }, { "amount": 1337, "code": "RI" }, { "amount": 648, "code": "UH" }] }],
        //   refundable: true
        // }
        this.refundDialog = true;
      } catch (e) {
        throw e;
      } finally {
        this.refunding = false;
      }
    },
    async updateByTime() {
      if (!this.isNeedUpdate) {
        clearInterval(this.updateIntervalId);
        return;
      }
      if (!this.allowUpdate) return;
      try {
        this.allowUpdate = false;
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Order.Update + `?data=${encodeURIComponent(this.item.workId)}`,
        );
        const order = resp.data
        if (!order || !order.workId)
          throw new Error('No Order Info');
        this.item = order;
        this.allowUpdate = true;
      } catch (e) {
        clearInterval(this.updateIntervalId);
        this.allowUpdate = false;
      }
    }
  }
};
</script>

<style scoped>
.titleCard {
  border: solid 2px orange !important;
}

.on-border-right {
  position: absolute;
  top: -15px;
  right: 20px;
}

.on-border-left {
  position: absolute;
  top: -20px;
  left: 20px;
}

.divider {
  font-size: 20px;
}

.dashed-divider {
  margin: auto 10px;
  border-top: 2px dashed rgb(114, 114, 114);
}

.btn-group,
.not-ticket {
  padding: 10px 0;
  color: #12121244;
}

.not-ticket span {
  font-size: 50px;
  color: orange;
  min-height: 100%;
}
</style>
